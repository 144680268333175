$border-radius: 0px;
$border-width: 1px;
$border-width-none: 0px;

$line-height: 22px;

$font-size-small: 8pt;
$font-size-medium: 10pt;
$font-size-big: 11pt;

$padding-none: 0px;
$padding-small: 2px;
$padding-medium: 4px;
$padding-big: 8px;
$padding-bigger: 16px;

$margin-none: 0px;
$margin-small: 2px;
$margin-medium: 4px;
$margin-big: 8px;
$margin-bigger: 16px;

$separator-height: 1px;

$map-menu-button-height: 40px;

$map-menu-button-width-small: 30px;
$map-menu-button-width-big: 130px;
$map-menu-button-height-small: 30px;
$map-menu-button-height-big: 30px;

$transition-time-out: 250ms;
$transition-time-in: 250ms;

$slide-time: 1s;
$slide-show-delay: 110ms;

$z-index-level-0: 0;
$z-index-level-1: 100;
$z-index-level-2: 200;
$z-index-level-3: 300;
$z-index-level-4: 400;
$z-index-level-5: 500;
$z-index-level-6: 600;
$z-index-level-7: 700;
$z-index-level-8: 800;
$z-index-level-9: 900;
$z-index-level-10: 1000;
$z-index-level-over-modal: 1100;
