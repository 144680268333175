@import './variables/Colors.scss';
@import './variables/Numbers.scss';

.app-container {
  width: 100%;
  height: 100%;

  overflow-y: auto;

  display: flex;
  flex-direction: column;

  .app-content-container {
    width: 100%;
    padding: 0.8rem 0;

    flex-grow: 1;
    height: 1px;
  }
}

.custom-control {
  &.custom-checkbox {
    .custom-control-label::before {
      border-radius: $border-radius;
      left: -1rem;
    }
    .custom-control-label::after {
      border-radius: $border-radius;
      left: -1rem;
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        border-color: $primary-color;
        background-color: $primary-color;
      }
      &::after {
        border-color: $primary-color;
        background-color: $primary-color;
      }
    }
  }

  &.custom-switch {
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        border-color: $primary-color;
        background-color: $primary-color;
      }
    }
  }

  &.custom-radio {
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        background-color: $radio-selected-color !important;
        border-color: $radio-selected-color !important;
      }
    }
  }
}

.custom-text-wrap {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-bold {
  font-weight: bold;
}

.progress {
  border-radius: $border-radius !important;
  border-color: $border-color !important;
  background-color: $secondary-color !important;

  .progress-bar {
    background-color: $primary-color !important;
  }
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $z-index-level-9;
  background: $overlay-background-color;

  pointer-events: visible;

  .loading-container {
    position: relative;
    width: 300px;
    height: 100px;

    top: calc(50% - 50px);
    left: calc(50% - 150px);
    .loading-label {
      color: $secondary-color;
      font-weight: bold;
    }
    .ci-spinner {
      position: relative;
      color: $primary-color;

      margin: $margin-big;
    }
  }
}

.ci-button-basic {
  display: block !important;
  padding: 0 !important;
  font-size: 1.14em !important;
  font-weight: bold !important;
  text-decoration: none !important;
  text-align: center !important;
  line-height: 0.4em !important;
  transition: background-color 0.3s linear !important;
  border: 2px solid $secondary-color !important;
  border-radius: 1px;
  height: 3rem;
  width: 25%;
  cursor: pointer;
}

.ci-button-hover {
  color: $primary-color !important;
  background-color: $secondary-color !important;
}

.ci-button-light-bg-hover {
  color: $secondary-color !important;
  background-color: $primary-color !important;
}

.ci-button {
  @extend .ci-button-basic;

  color: $secondary-color !important;
  background-color: $primary-color !important;

  &:hover {
    @extend .ci-button-hover;
  }
}

.ci-button-light-bg {
  @extend .ci-button-basic;

  color: $primary-color !important;
  background-color: $secondary-color !important;

  &:hover {
    @extend .ci-button-light-bg-hover;
  }
}

.modal-open .modal {
  overflow-y: hidden;
}

.modal-dialog {
  border-radius: $border-radius;

  .modal-content {
    border-radius: $border-radius;

    textarea {
      height: 200px;
    }
  }
}

.hover-icon {
  cursor: pointer;
  &:hover {
    color: $tertiary-color !important;
    background-color: $transparent !important;
  }
}

.list-group {
  color: black;
  border-radius: $border-radius;
  border-width: $border-width-none;
  overflow-y: auto;
  overflow-x: hidden;

  .list-group-item {
    border-radius: $border-radius;
    border-width: $border-width-none;

    &.hover-item {
      cursor: pointer;

      &:hover {
        background: $tertiary-color;
        color: $secondary-color;

        .hover-icon {
          &:hover {
            color: black !important;
            background-color: $transparent !important;
          }
        }

        .separator {
          margin: $margin-big 0;
          height: $separator-height;
          background-color: $secondary-color;
          width: 50%;
        }
      }
    }

    &.selectable {
      cursor: pointer;

      &.active {
        background: $primary-color;
        color: $secondary-color;

        .hover-icon {
          &:hover {
            color: black !important;
            background-color: $transparent !important;
          }
        }

        .separator {
          margin: $margin-big 0;
          height: $separator-height;
          background-color: $secondary-color;
          width: 50%;
        }
      }
    }

    .additional-area {
      border-width: $border-width-none;
      padding: 15px 0px;
    }
  }
}

.collapse-content {
  background-color: $secondary-color;

  &.shown {
    &.width {
      width: 350px;
      height: 100%;
      -webkit-transition: width $transition-time-in ease-in;
      -moz-transition: width $transition-time-in ease-in;
      -o-transition: width $transition-time-in ease-in;
      transition: width $transition-time-in ease-in;
    }

    &.height {
      overflow-y: hidden;
      width: 100%;
      -webkit-transition: max-height $transition-time-in ease-in;
      -moz-transition: max-height $transition-time-in ease-in;
      -o-transition: max-height $transition-time-in ease-in;
      transition: max-height $transition-time-in ease-in;
    }
  }

  &.hidden {
    &.width {
      width: 0px;
      height: 100%;
      -webkit-transition: width $transition-time-out ease-in;
      -moz-transition: width $transition-time-out ease-in;
      -o-transition: width $transition-time-out ease-in;
      transition: width $transition-time-out ease-in;
    }

    &.height {
      overflow-y: hidden;
      width: 100%;
      max-height: 0px !important;
      -webkit-transition: max-height $transition-time-out ease-in;
      -moz-transition: max-height $transition-time-out ease-in;
      -o-transition: max-height $transition-time-out ease-in;
      transition: max-height $transition-time-out ease-in;
    }
  }
}

.popover {
  color: black;
  position: absolute;
  z-index: $z-index-level-8;
  background: $secondary-color;

  .popover-body {
    padding: $padding-none;
    background: $secondary-color;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.15) 0px 8px 16px;

    .sketch-picker {
      box-shadow: none !important;
      border-radius: $border-radius !important;
    }
  }
}

.custom-tooltip {
  max-width: 400px;
  padding: $padding-medium;
  color: $secondary-color;
  background: $primary-color;
  border: $border-width solid $secondary-color;
  z-index: $z-index-level-over-modal;
}

.tooltip-icon {
  cursor: help;
}

.warning-message-container {
  position: absolute;

  padding: $padding-big;

  display: flex;
  justify-content: flex-start;

  flex-wrap: wrap;
  align-items: center;

  z-index: $z-index-level-8;

  font-size: $font-size-medium;

  top: 0px;
  left: 0px;
  right: 0px;

  &.show {
    display: flex;
  }

  &.hidden {
    display: none;
  }

  &.info {
    background-color: $message-normal;
  }
  &.warn {
    background-color: $message-warning;
  }
  &.error {
    background-color: $message-error;
  }
  &.success {
    background-color: $message-success;
  }

  .warning-message-icon {
    height: 100%;
    margin-right: $margin-big;
  }

  .warning-message-title {
    height: 100%;
    font-weight: bold;
    margin-right: $margin-big;
  }

  .warning-message-content {
    height: 100%;
  }
}

.required-hint-footer {
  position: absolute;
  left: 0px;
  bottom: 5.5rem;

  padding: 0 calc(#{$padding-big} + 24px);

  font-size: $font-size-small;

  div {
    // position: absolute;

    border-radius: 5px;
    padding: 4px 8px;
    background-color: $area-list-header-color;
  }
}
