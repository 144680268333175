@import './variables/Numbers.scss';
@import './variables/Colors.scss';

.modal-footer-button {
  width: 100%;
}

.CalendarDay__selected {
  background: $primary-color;

  &:hover {
    background: $tertiary-color;
  }
}
.CalendarDay__highlighted_calendar {
  background: $primary-color !important;
  color: $secondary-color !important;

  &:hover {
    background: $tertiary-color !important;
  }
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid $primary-color;
}

.DateInput_input {
  // font-size: $font-size-medium !important;
  // line-height: 22px !important;
  font-style: normal !important;
  font-family: 'Open Sans', Helvetica, Arial, Verdana, sans-serif;
  background: $input-background !important;
  border-bottom: 0 !important;
  padding: $padding-big !important;
}

.SingleDatePickerInput__withBorder {
  border: $border-width solid $border-color !important;
  background-color: $input-background !important;

  &.SingleDatePickerInput__disabled {
    background: $disabled-color !important;
    color: $disabled-color-text !important;
    border-color: $disabled-color-border !important;
  }
}

.SingleDatePicker_picker {
  z-index: $z-index-level-over-modal !important;
}

.distribution-appointment-dropdown-option {
  width: 100%;

  display: flex;
  align-items: baseline;

  .distribution-appointment-name {
    margin-right: $margin-big;
  }

  .distribution-appointment-date {
  }
}

.kauf-da-duration-price-dropdown-option {
  display: flex;
  align-items: center;
  width: 100%;

  .kauf-da-duration-price-name {
    padding-right: $padding-medium;
  }
  .kauf-da-duration-price-date {
  }
}

.additional-option-list-container {
  padding-top: $padding-bigger;

  .kauf-da-duration-price-dropdown-option {
    display: flex;
    align-items: center;

    .kauf-da-duration-price-name {
      padding-right: $padding-medium;
    }
    .kauf-da-duration-price-date {
    }
  }
}

.order-modal {
  .order-modal-body {
    display: flex;
    flex-direction: column;

    min-height: 80vh;

    form {
      display: flex;
      flex-grow: 1;
      // height: 100%;

      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .content-container {
    margin: $margin-bigger;
    border-radius: 5px;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .logo-container {
      margin-top: $margin-bigger;
      margin-bottom: $margin-big;

      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .additional-option-logo {
      max-height: 17px;
    }

    .sub-section-title {
      font-size: 1.1rem;
      font-weight: bold;

      &.summary-item-subtitle {
        padding: 0 $padding-bigger;
      }
    }

    .form-label {
      font-weight: 600;

      white-space: nowrap !important;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .separator {
      width: 100%;
      height: 1px;

      margin-bottom: $margin-bigger;
      margin-top: $margin-big;

      background: rgb(43, 43, 43);
    }

    .upper-content {
      padding: $padding-big $padding-bigger;

      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      background-color: $area-list-header-color;

      font-size: 11pt;
      font-weight: bold;

      &.long-text {
        font-size: $font-size-medium;
        font-weight: normal;
      }
    }

    .lower-content {
      padding: $padding-bigger $padding-bigger;

      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .switch-container {
        display: flex;
        align-items: center;
      }

      .print-item-container {
        margin: 0;
        padding: $padding-big 0;

        align-items: center;

        .subsidiary-name {
          font-weight: 600;
        }

        .custom-file-label {
          overflow: hidden !important;
          border-radius: $border-radius !important;
          border-color: $border-color !important;

          &::after {
            content: 'Durchsuchen' !important;
          }
        }
      }

      .custom-switch-container {
        label {
          padding-left: 2rem;
          font-weight: 600;
          font-size: $font-size-big;
        }

        .invalid-feedback {
          padding-left: 2rem;
        }

        .custom-switch-info {
          padding-left: 3.5rem;
        }
      }

      .empty-list-container {
        align-content: center;
        text-align: center;

        font-weight: 600;
      }

      table.summary-table {
        th {
          font-weight: 600;

          padding: $padding-medium $padding-bigger;

          &:last-child {
            width: 100%;
          }
        }

        td {
          text-align: right;
          white-space: nowrap;

          padding: $padding-medium $padding-bigger;

          &.total {
            font-weight: 600;

            &.label {
              text-align: left;
            }
          }

          &:last-child {
            padding: $padding-medium 0;
          }
        }
      }

      .kauf-da-container {
        .additional-option-selection-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .additional-item-select {
        label {
          font-size: $font-size-medium;
          font-weight: 500;
        }
      }

      .additional-item-col {
        display: flex;
        padding: $padding-big;
        align-items: center;

        .additional-item-drop {
          width: 100%;
        }

        .additional-item-drop__control {
          height: 10px;
        }

      }

      .additoinal-item-table-header {
        .additoinal-item-table-header-item {
          font-weight: 600;
          padding: $padding-big;
        }
      }
    }
  }
}
