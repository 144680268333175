@import './variables/Colors.scss';
@import './variables/Numbers.scss';

.header-container {
  margin: 0;

  display: flex;
  flex-direction: row;

  .spacer {
    padding: 0;

    flex-grow: 1;
    width: 1px;
  }

  .header-login-container {
    button.ci-button {
      width: 100%;
    }

    .login-popover-button {
      width: 100%;
    }
  }

  .header-menu-container {
    .user-menu-container {
      margin: 0px;
      padding: 0.4rem;

      display: flex;
      flex-direction: row;

      .user-avatar {
        width: 0px;
        flex-grow: 1;

        text-align: right;

        margin-top: auto;
        margin-bottom: auto;

        padding-right: 0.8rem;

        img {
          height: 30px;
          width: 30px;
        }
      }

      .user-menu {
        padding: 0px;
        flex-grow: 0;
        flex-basis: auto;
        width: auto;
        text-align: right;

        .user-menu-dropdown {
          width: auto;
          button {
            &.dropdown-toggle {
              font-size: $font-size-big;
              font-weight: bold;
              background: transparent;
              border-color: transparent;
              padding: 0.2rem;
            }

            &.dropdown-item {
              &:hover {
                background: $tertiary-color;
                color: $secondary-color;
              }
            }
          }

          .dropdown-menu {
            border-radius: $border-radius;
            border-width: $border-width;
            border-color: $border-color;
          }
        }
      }
    }
  }
}
