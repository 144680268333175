@import './variables/Numbers.scss';
@import './variables/Colors.scss';

.wizard-container {
  // height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  .wizard-title {
    font-weight: bold;
    padding-bottom: $padding-bigger;
  }

  .title-bar {
    margin: 0;
    height: 50px;

    .title-bar-item {
      background: $primary-color;
      margin: 0 1px;

      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      color: $secondary-color;
      font-size: 13pt;
      font-weight: bold;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }

      &.active:before {
        content: '';
        position: absolute;

        right: calc(50% - 10px);
        // left: 50%;
        bottom: 0px;

        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        border-bottom: 10px solid white;
      }
    }
  }

  .wizard-content {
    height: 0px;
    flex-grow: 1;

    overflow-y: auto;
    overflow-x: hidden;

    margin-bottom: $padding-bigger;

    position: relative;

    .wizard-content-item-wrapper {
      height: 100%;
      width: 100%;

      position: absolute;
      top: 0;
      left: 0;

      &.show-from-right {
        overflow-y: auto;

        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);

        animation: $slide-time ease-in $slide-show-delay rightIn;
        -webkit-animation: $slide-time ease-in $slide-show-delay rightIn;
        -moz-animation: $slide-time ease-in $slide-show-delay rightIn;
        -ms-animation: $slide-time ease-in $slide-show-delay rightIn;

        .wizard-content-item {
          visibility: visible;

          transition: visibility $slide-time $slide-show-delay;
          -webkit-transition: visibility $slide-time $slide-show-delay;
          -moz-transition: visibility $slide-time $slide-show-delay;
          -ms-transition: visibility $slide-time $slide-show-delay;
        }
      }

      &.show-from-left {
        overflow-y: auto;

        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);

        animation: $slide-time ease-in $slide-show-delay leftIn;
        -webkit-animation: $slide-time ease-in $slide-show-delay leftIn;
        -moz-animation: $slide-time ease-in $slide-show-delay leftIn;
        -ms-animation: $slide-time ease-in $slide-show-delay leftIn;

        .wizard-content-item {
          visibility: visible;

         transition: visibility $slide-time $slide-show-delay;
          -webkit-transition: visibility $slide-time $slide-show-delay;
          -moz-transition: visibility $slide-time $slide-show-delay;
          -ms-transition: visibility $slide-time $slide-show-delay;
        }
      }

      &.hidden-to-left {
        overflow-y: hidden;

        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);

        animation: $slide-time ease-in 0s leftOut;
        -webkit-animation: $slide-time ease-in 0s leftOut;
        -moz-animation: $slide-time ease-in 0s leftOut;
        -ms-animation: $slide-time ease-in 0s leftOut;

        .wizard-content-item {
          visibility: hidden;

          transition: visibility 0s $slide-time;
          -webkit-transition: visibility 0s $slide-time;
          -moz-transition: visibility 0s $slide-time;
          -ms-transition: visibility 0s $slide-time;
        }
      }

      &.hidden-to-right {
        overflow-y: hidden;

        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);

        animation: $slide-time ease-in 0s rightOut;
        -webkit-animation: $slide-time ease-in 0s rightOut;
        -moz-animation: $slide-time ease-in 0s rightOut;
        -ms-animation: $slide-time ease-in 0s rightOut;

        .wizard-content-item {
          visibility: hidden;

          transition: visibility 0s $slide-time;
          -webkit-transition: visibility 0s $slide-time;
          -moz-transition: visibility 0s $slide-time;
          -ms-transition: visibility 0s $slide-time;
        }
      }

      .wizard-content-item {
        height: 100%;
        width: 100%;
        overflow-x: hidden;
      }
    }
  }

  .wizard-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;

    justify-content: flex-start;

    .wizard-button {
      width: 150px;

      .ci-button {
        width: 100%;
      }

      &.next {
        margin-left: auto;
      }
    }
  }
}

@keyframes rightOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes leftOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes rightIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes leftIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
