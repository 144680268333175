$primary-color: rgb(83, 164, 198);
$secondary-color: rgb(255, 255, 255);
$tertiary-color: rgb(63, 175, 212);

$disabled-color: rgb(215,215,215);
$disabled-color-border: rgb(215,215,215);
$disabled-color-text: hsl(0,0%,60%);

$area-list-header-color: rgb(205, 205, 205);
$subsidiary-item-color: rgb(235, 235, 235);

$transparent: rgba(0, 0, 0, 0);
$input-background: rgb(252, 252, 252);
$radio-selected-color: rgb(83, 164, 198);

$overlay-background-color: rgba(0, 0, 0, 0.7);
$list-separator-color: rgb(235, 235, 235);

$border-color: rgb(0, 0, 0);
$border-color-list: rgba(0, 0, 0, 0.125);
$form-border-color: $border-color;

$warn-icon-color: rgb(255, 155, 48);

$message-normal: rgba(83, 164, 198, 0.9);
$message-warning: rgba(255, 230, 0, 0.9);
$message-error: rgba(236, 22, 22, 0.9);
$message-success: rgba(49, 238, 58, 0.9);

$font-color-active: rgb(73, 80, 87);
$font-color-inactive: rgb(102, 111, 120);
