@import './variables/Colors.scss';
@import './variables/Numbers.scss';

.distribution-container {
  height: 100%;
  margin: 0;
  align-items: center;

  .map-container {
    height: 100%;

    .map-iframe {
      height: 100%;
      width: 100%;
    }
  }

  .total-circulation {
    padding: $padding-small 0;

    color: $secondary-color;
    font-weight: bolder;
    font-size: $font-size-small;
  }

  .area-container {
    height: 100%;

    display: flex;
    flex-direction: column;

    .area-list-container {
      height: 0;
      flex-grow: 1;
      overflow: auto;

      margin: 0;
      padding: 0.5rem 0;
    }
  }
}

.offer-button {
  font-size: $font-size-big !important;
  width: 100%;
  // height: 3rem;
}

.client-select-label {
  margin-top: auto;
  margin-bottom: auto;

  color: $secondary-color;
  font-weight: bold;
  font-size: $font-size-medium;
}

.client-select-container {
  .form-group {
    margin-top: auto;
    margin-bottom: auto;
  }
}
