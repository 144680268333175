@import './variables/Colors.scss';
@import './variables/Numbers.scss';

.tooltip {
  &.show {
    opacity: 1 !important;
  }
  .tooltip-inner {
    background-color: $primary-color;
    color: $secondary-color;
    border-radius: $border-radius;
  }
  .arrow::before {
    border-left-color: $primary-color !important;
  }
}

.selected-subsidiary-list {
  &.list-group {
    padding: 0px !important;
    font-size: $font-size-small;

    .list-group-item {
      padding: $padding-none !important;

      &.subsidiary-area-list-item {
        padding: $padding-none !important;

        &:first-child {
          // padding-top: $padding-bigger !important;
        }

        &:last-child {
          // padding-bottom: $padding-big !important;
        }

        .subsidiary-area-list-row {
          margin: $margin-none;
          padding-top: $padding-none;
          padding-bottom: $padding-none;
          background: $subsidiary-item-color;

          &:last-child {
            // padding-top: $padding-none !important;
            // padding-bottom: $padding-big !important;
          }

          &:first-child {
            padding: $padding-big 0 !important;
          }

          &:only-child {
            // padding-bottom: $padding-big !important;
          }

          .subsidiary-icons {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
          }
        }

        .area-item {
          border-bottom: $border-width solid $border-color-list;

          padding-top: $padding-big !important;
          padding-bottom: calc(#{$padding-big} - #{$border-width}) !important;

          &:last-child {
            padding-top: $padding-big !important;
          }

          &:first-child {
            border-top: $border-width solid $border-color-list;
            padding-top: calc(#{$padding-big} - #{$border-width}) !important;
          }

          & > .row {
            padding-bottom: calc(#{$padding-big} - #{$border-width}) !important;

            &:only-child {
              padding-bottom: 0px !important;
            }
          }
        }

        .additional-area {
          padding-top: $padding-big !important;
          padding-bottom: calc(#{$padding-big} - #{$border-width}) !important;

          border-bottom: $border-width solid $border-color-list;

          &:last-child {
            padding-top: $padding-big !important;
            padding-bottom: 0px !important;
            border-bottom: 0px;
          }

          &:first-child {
            border-top: $border-width solid $border-color-list;
            padding-top: calc(#{$padding-big} - #{$border-width}) !important;
          }
        }
      }
    }
  }
}

.area-list {
  padding-top: $padding-none;
  padding-bottom: $padding-none;
  font-size: $font-size-small;

  .area-item {
    border-bottom: $border-width solid $border-color-list;

    padding-top: $padding-big !important;
    padding-bottom: calc(#{$padding-big} - #{$border-width}) !important;
    padding-left: $padding-none;
    padding-right: $padding-none;

    &:last-child {
      padding-top: $padding-big !important;
    }

    &:first-child {
      border-top: $border-width solid $border-color-list;
      padding-top: calc(#{$padding-big} - #{$border-width}) !important;
    }

    &:only-child {
      padding: none;
    }

    & > .row {
      padding-bottom: calc(#{$padding-big} - #{$border-width}) !important;

      &:only-child {
        padding-bottom: 0px !important;
      }
    }
  }

  .additional-area {
    padding-top: $padding-big !important;
    padding-bottom: calc(#{$padding-big} - #{$border-width}) !important;

    border-bottom: $border-width solid $border-color-list;

    &:last-child {
      padding-top: $padding-big !important;
      padding-bottom: 0px !important;
      border-bottom: 0px;
    }

    &:first-child {
      border-top: $border-width solid $border-color-list;
      padding-top: calc(#{$padding-big} - #{$border-width}) !important;
    }
  }
}

.icon-warn {
  color: $warn-icon-color;
}

.icon-remove {
  cursor: pointer;
}

.icon-localities {
  cursor: pointer;
}

.area-list-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  font-size: $font-size-small;

  // background: $secondary-color;
  background: $area-list-header-color;
  color: black;
  padding: 0.5rem;

  .item {
    display: flex;

    &.left {
      justify-content: flex-start;
    }

    &.right {
      justify-content: flex-end;
    }
  }
}
