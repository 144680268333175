@import './variables/Colors.scss';
@import './variables/Numbers.scss';

body,
html {
  margin: 0px;
  padding: 0px;
  height: 100%;
  overflow: hidden;

  right: 0px;
  font-size: $font-size-medium;
  line-height: $line-height;
  font-style: normal;
  font-family: 'Open Sans', Helvetica, Arial, Verdana, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  background: $input-background !important;
  border-width: $border-width !important;
  border-color: $border-color !important;
  border-radius: $border-radius !important;

  &:disabled {
    background: $disabled-color !important;
    color: $disabled-color-text !important;
    border-color: $disabled-color-border !important;
  }
}

button {
  border-width: $border-width !important;
  border-radius: $border-radius !important;

  &:focus {
    box-shadow: none !important;
  }
}

div[role='button'] {
  &:focus {
    outline: initial !important;
  }
}

td {
  vertical-align: middle !important;
}

.input-group-text {
  border-width: $border-width !important;
  border-color: $border-color !important;
  border-radius: $border-radius !important;
}

.custom-control-label {
  padding-left: $padding-medium;
}

.flex-grow-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.DateInput_input.DateInput_input_1{
  font-size: $font-size-medium !important;
  padding: 4px !important;
}

#root {
  height: 100%;
  width: 100%;

  background: $primary-color;
}
