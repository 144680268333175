@import './variables/Colors.scss';
@import './variables/Numbers.scss';

.selection-container {
  height: 100%;
  padding: 0;

  flex-direction: column;
  display: flex;

  .selection-header-container {
    padding: $padding-big;

    .selection-header {
      text-align: center;
      font-size: $font-size-big;
      font-weight: bold;
    }
  }

  .selection-item-container {
    flex-grow: 1;
    height: 1px;
    margin: 0;

    .choice-container {
      color: $primary-color;
      background: $secondary-color;
      cursor: pointer;

      &:hover {
        color: $secondary-color;
        background: $primary-color;
      }
    }
  }
}
